<template>
  <div class="row" ref="table">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Permission List</h4>
          <router-link v-if="$store.getters['auth/checkAccess']('permissions/create')"
                     class="btn btn-info btn-wd"
                    :to="'/permissions/create'">
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>

        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="paginationData.pageSize"
              @change="getData('changePageSize')"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in paginationData.perPageOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            <el-input type="search"
                      class="mb-3"
                      style="width: 200px"
                      placeholder="Search records"
                      v-model="searchQuery"
                      @change="getData('updateSearch')"
                      aria-controls="datatables"/>
          </div>

          <div class="col-sm-12">
            <el-table stripe
                      style="width: 100%;"
                      :data="tableData"
                      border>

              <el-table-column v-for="column in tableColumns"
                               :key="column.label"
                               :min-width="column.minWidth"
                                align="center"
                               :prop="column.prop"
                               :label="column.label">
              </el-table-column>

              <el-table-column
                :min-width="120"
                fixed="right"
                align="center"
                label="Actions">
                <template slot-scope="props">
                  <router-link v-if="$store.getters['auth/checkAccess']('permissions/update')"  v-tooltip.top-center="'Edit'" class="btn-warning btn-simple btn-link"
                     :to="'/permissions/edit/'+props.row.id"><i
                    class="fa fa-edit"></i></router-link>

                  <a v-if="$store.getters['auth/checkAccess']('permissions/delete')"  v-tooltip.top-center="'Delete'" class="btn-danger btn-simple btn-link"
                     @click="openDeleteModal(props.row)"><i class="fa fa-times"></i></a>
                </template>
              </el-table-column>


            </el-table>
          </div>


        </div>

        <div slot="footer">
          <NLPagination class="pagination-no-border"
                        v-model="paginationData.currentPage"
                        @input="getData('changePageNumber')"
                        :per-page="paginationData.pageSize"
                        :total="paginationData.totalItems">
          </NLPagination>
        </div>

      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this permission?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

    </div>

  </div>
</template>
<script>
import {Table, TableColumn, Select, Option} from 'element-ui'
import {NewPagination as NLPagination} from 'src/components/index'
import {DeleteModal as DeleteModal} from 'src/components/index'

export default {
  components: {
    NLPagination,
    DeleteModal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },

  data() {

    return {
      loader: {},
      paginationData: {
        pageSize: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        totalItems: 0
      },

      searchQuery: '',

      tableColumns: [
        {
          prop: 'title',
          label: 'Title',
          minWidth: 200
        },
        {
          prop: 'code',
          label: 'Code',
          minWidth: 200
        }
      ],

      tableData: [],

      deleteModalVisibility: false,
      toDeleteId: undefined
    }

  },

  methods: {

    getData(action) {
      switch (action) {
        case 'updateSearch':
          this.updateCurrentPage(1);
          break;
        case 'changePageSize':
          this.updateCurrentPage(1);
          break;
        case 'confirmDelete':
          this.searchQuery = '';
          this.updateCurrentPage(1);
          break;
        case 'changePageNumber' :
          break;
      }

      this.loader = this.$loading.show({
        container: this.$refs.table
      });

      this.axios.get("permissions/index?page=" + this.paginationData.currentPage +
        "&pageSize=" + this.paginationData.pageSize +
        "&searchQuery=" + this.searchQuery).then((response) => {
        this.tableData = response.data.data;
        this.paginationData.totalItems = response.data.pagination.total;
        this.loader.hide();
      }).catch((error) => {
        console.error(error);
      });
    },

    updateCurrentPage(pageNumber) {
      this.paginationData.currentPage = pageNumber;
    },

    openDeleteModal(row) {
      this.deleteModalVisibility = true;
      this.toDeleteId = row.id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      this.axios.delete("permissions/delete/" + this.toDeleteId).then((response) => {

        this.$notify({
          message: "Permission deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.getData("confirmDelete");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    }
  },

  mounted() {
    this.getData();
  },


}
</script>
<style>
</style>
